<template>
    <div class="container" v-if="data.length != 0">
        <h3>NOTICIAS RECIENTES</h3>


        <div class="contenedor-squares js-noticias-recientes row-start ais fww">

            <div class="square" v-for="(el,key) in data" :key="key">
                <router-link :to="'/es/comunicacion/noticias/'+el.slug" class="enlace-cover"></router-link>
                <div class="contenedor-imagen"
                    :style="`background:url(${el.header_img.url}) center/cover no-repeat;`">
                </div>
                <h5> <span>{{el.title}}</span> </h5>
            </div>

        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'


    export default {
        name: 'noticias-home',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            loading: false
        }),
        methods: {
            ...mapActions(['requestEntries']),
            setData() {
                this.loading = true;

                let data = {
                    length: 4
                }

                this.requestEntries(data).then(r => {
                    this.data = r.data.filter(e => e.slug != this.$route.params.id);
                    this.loading = false;
                })
            }
        },

    }
</script>

<style scoped lang="scss">
    .container{
        padding: 0 20px;
        margin-top: 60px;
    }

    h3{
        font-size: 28px;
    }
</style>