import { render, staticRenderFns } from "./noticias-recientes.vue?vue&type=template&id=1c90fc04&scoped=true"
import script from "./noticias-recientes.vue?vue&type=script&lang=js"
export * from "./noticias-recientes.vue?vue&type=script&lang=js"
import style0 from "./noticias-recientes.vue?vue&type=style&index=0&id=1c90fc04&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c90fc04",
  null
  
)

export default component.exports